// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buy-now-js": () => import("./../src/pages/buy-now.js" /* webpackChunkName: "component---src-pages-buy-now-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-extended-license-js": () => import("./../src/pages/extended-license.js" /* webpackChunkName: "component---src-pages-extended-license-js" */),
  "component---src-pages-free-icons-js": () => import("./../src/pages/free-icons.js" /* webpackChunkName: "component---src-pages-free-icons-js" */),
  "component---src-pages-illustration-js": () => import("./../src/pages/illustration.js" /* webpackChunkName: "component---src-pages-illustration-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premium-icons-js": () => import("./../src/pages/premium-icons.js" /* webpackChunkName: "component---src-pages-premium-icons-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-standard-license-js": () => import("./../src/pages/standard-license.js" /* webpackChunkName: "component---src-pages-standard-license-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

